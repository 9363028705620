.sideBar {
  position: relative;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 300px;
  overflow-y: scroll;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.sideBar ul {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sideBar button {
  border: none;
  width: 100%;
  padding: 5px;
  text-align: left;
}

.sideBar button:hover {
  background-color: #0004;
}

.sideBar .selected {
  border-left: 4px solid var(--primary-color);
  background-color: #0003;
}
