.background {
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #0006;
}

.windows {
  position: relative;
  z-index: 1500;
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 500px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ccc;
  overflow: hidden;
  box-shadow: 0 0 10px 5px #0006;
}

.container {
  display: flex;
  gap: 20px;
  height: 100%;
}

.close {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.close h3 {
  flex-grow: 1;
  margin: 0;
  text-align: left;
}
