.background {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 50px;
  background-color: #0004;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  overflow-y: auto;
}

.container {
  margin-bottom: 100px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}

.header {
  display: flex;
}

.headerTitle {
  flex-grow: 1;
  text-align: left !important;
}

.headerClose {
  font-size: 20px;
  color: grey;
  border: none;
  background-color: inherit;
}

.headerClose:hover {
  color: red;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

/* FORM */
.formHeader {
  display: flex;
  border: 1px solid #ccc;
}

.formHeader div {
  flex-grow: 1;
  padding: 10px;
}

.formHeader label {
  margin-right: 10px;
  font-weight: bold;
}

.formHeader input {
  border: 1px solid #ccc;
}

.table {
  border: 1px solid #ccc;
}

.table tr {
  padding: 10px;
  border: 1px solid #ccc;
}

.table button,
.table select {
  height: 100%;
  width: 100%;
}

.row th:nth-child(2),
.row th:nth-child(4) {
  width: 300px !important;
}

.row th:nth-child(1),
.row th:nth-child(3),
.row th:nth-child(5),
.row th:nth-child(6),
.row td:nth-child(5) input,
.row td:nth-child(6) input {
  width: 120px !important;
}

.table td,
.table th {
  border: 1px solid #ccc;
  text-align: center;
}

.table td:nth-child(7) {
  width: 50px;
}

.table input {
  border: 1px solid #ccc;
}

.table .add {
  grid-column: 1 / 4;
  display: block;
  border-color: red;
  height: 100px;
  width: 100%;
}

.table .flex {
  display: flex;
  border: none;
  outline: 1px solid #ccc;
}

.table .flex span {
  flex-grow: 1;
  text-align: center;
}

.btnAddRow {
  width: 100%;
}

.tiresFlex {
  display: flex;
  justify-content: space-between;
}

.tiresFlex div input[type="text"] {
  width: 100px;
}
