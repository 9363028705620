.dataSelector {
  display: flex;
  flex-direction: column;
}

.dropBox {
  flex-grow: 1;
  width: 100%;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: scroll;
}

.dropItem {
  display: flex;
  padding: 5px 10px;
}

.dropItem input {
  margin-right: 10px;
}
