.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  margin: 10px;
  border-radius: 10px;
  text-decoration: none;
  color: grey;
}

.item img {
  width: 25px;
  margin-right: 10px;
}

.item:hover {
  color: var(--primary-color);
  background-color: var(--background-color);
}

.item:hover img {
  filter: var(--primary-filter);
}

.itemSelected {
  color: var(--primary-color);
  border-left: 5px solid var(--primary-color);
  background-color: var(--dark-background);
}
