.dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 20px;
  overflow: hidden;
}

.dashboard h3 {
  margin: 0;
  text-align: center;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.searchFilters {
  display: flex;
  gap: 10px;
}

.searchBar {
  display: flex;
}

.searchBar input {
  border-radius: 5px 0 0 5px;
}

.searchBar button {
  border-color: var(--background-color);
  background-color: var(--background-color);
  border-radius: 0 5px 5px 0;
}

.tableContainer {
  position: relative;
  width: 100%;
  height: 100%;
  outline: 1px solid var(--border-color);
  border-radius: 5px;
  overflow-y: scroll;
}

.table {
  table-layout: fixed;
  width: 100%;
  overflow-y: scroll;
}

.table thead {
  position: sticky;
  top: 0;
  background-color: #fff; /* Asegura que el encabezado se destaque sobre las filas */
  z-index: 10;
  outline: 1px solid var(--border-color);
}

.table tr th,
.table tr td {
  padding: 10px 15px;
}

.table tr th:nth-child(7),
.table tr th:nth-child(8),
.table tr td:nth-child(7),
.table tr td:nth-child(8) {
  width: 80px;
}

.emptyRows {
  position: absolute;
  top: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.firstRow {
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  padding: 10px;
  padding-right: 20px;
  padding-left: 30px;
  background-color: var(--backgorund);
}
