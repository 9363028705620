.loading {
  position: absolute;
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #0008;
}

.loading img {
  width: 50px;
  filter: drop-shadow(0 0 10px black);
}
