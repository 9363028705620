.background {
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--dark-background);
}

.form {
  width: 350px;
  padding: 20px;
  border-radius: 5px;
  background-color: var(--background);
}

.form .close {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.form .close h4 {
  flex-grow: 1;
  margin: 0;
}

.form .container div {
  margin-bottom: 10px;
}

.form .container div {
  margin-bottom: 10px;
}
