.sideBar {
  position: relative;
  z-index: 600;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100vh;
  border-right: 1px solid var(--border-color);
}

.sideBar {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  font-size: 15px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  border-bottom: 1px solid var(--border-color);
}

.logo img {
  height: 80%;
  filter: invert(80%);
}
