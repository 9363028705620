.background {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 50px;
  width: 100%;
  height: 100%;

  max-height: 100vh;

  background-color: #0004;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  overflow-y: auto;
}

.container {
  padding: 20px;
  height: max-content;
  border-radius: 10px;
  background-color: white;
}

.header {
  display: flex;
}

.headerTitle {
  flex-grow: 1;
  text-align: left !important;
}

.headerClose {
  font-size: 20px;
  color: grey;
  border: none;
  background-color: inherit;
}

.headerClose:hover {
  color: red;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.form table,
.form table tr,
.form table td {
  border: 1px solid #ccc;
}

.form table td {
  padding: 10px;
}

.textToComplete {
  width: 100%;
  margin: 20px 0px;
}

.textToComplete .row {
  display: flex;
  margin-top: 10px;
}

.textToComplete .row label {
  flex-grow: 1;
}

.textToComplete .row label select {
  margin-left: 10px;
}

.tableBody textarea {
  min-height:90px;
}

.textToComplete .row input {
  border: none;
  border-bottom: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 20px;
}

.tableBody tr td:nth-child(4) input,
.tableBody tr td:nth-child(5) input {
  width: 100px !important;
}

.tableBody tr td:nth-child(1) {
  width: 200px !important;
}

.tableBody tr td:nth-child(3) {
  width: 500px !important;
}

.repair {
  display: flex;
  flex-direction: column;
}

.tableBody td input {
  display: block;
}
