.background {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #0004;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.container {
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}

.header {
  display: flex;
}

.headerTitle {
  flex-grow: 1;
  text-align: left !important;
}

.headerClose {
  font-size: 20px;
  color: grey;
  border: none;
  background-color: inherit;
}

.headerClose:hover {
  color: red;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}