.dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 100vh;
  padding: 20px;
  overflow: hidden;
}

.dashboard h3 {
  margin: 0;
  text-align: center;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.table {
  flex-grow: 1;
  outline: 1px solid var(--border-color);
  overflow: hidden;
  table-layout: fixed;
  border-radius: 5px;
  width: 100%;
}

.table .body {
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
  overflow-y: scroll;
  height: 100%;
}

.table .body::-webkit-scrollbar {
  width: 10px;
  border-left: 1px solid var(--border-color);
}

.table .body::-webkit-scrollbar-track {
  background-color: transparent;
}

.table .body::-webkit-scrollbar-thumb {
  background-color: var(--panel); /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
}

.emptyRows {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.firstRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 50px 50px 50px;
  gap: 10px;
  padding: 10px;
  outline: 1px solid var(--border-color);
  padding-right: 20px;
  padding-left: 30px;
  background-color: var(--backgorund);
}

.firstRow span:last-child {
  grid-column: 5 / 6;
  text-align: center;
}

.left {
  display: flex;
  gap: 10px;
}
