.filter .btnFilter {
  display: flex;
  align-items: center;
  gap: 10px;
  border-color: var(--background-color);
  background-color: var(--background-color);
}

.filter .btnFilter img {
  width: 20px;
  filter: var(--primary-filter);
}

.filter .btnFilter:hover {
  background-color: var(--bs-btn-hover-bg);
}

.filter .btnFilter:hover img {
  width: 20px;
  filter: invert(1);
}

.filterContainer {
  position: absolute;
  z-index: 1000;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
  flex-direction: column;
  width: 250px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 3px #0004;
  background-color: white;
}

.filterContainer button {
  margin-top: 20px;
  margin-left: 0;
}
